.order-form-new {
  .choose-order-supplies {
    label {
      display: flex;
      align-items: center;
    }

    .supply-checkbox-container {
      padding-right: 15px;
    }

    input {
      margin: 0;
    }

    .description {
      color: #999;
    }

    .choose-supplies-button-group {
      display: flex;
      justify-content: space-around;
      padding-top: 15px;
      margin: 1rem -20px -1rem -20px;
      margin-top: 1rem;

      button {
        width: 25%;
      }
    }
  }

  .order-form-prompt {
    color: #4281a6;
    margin-bottom: 5px;
    font-style: italic;
    font-size: 1rem;

    .supply-requested {
      font-weight: 700;
      color: #aa0000;
      margin: 0 2px;
    }
  }

  .order-form-warning {
    padding: 10px;
    border: 1px solid #ff3333;
    color: #ff3333;
    background: #ffcccc33;
    font-weight: 600;
    margin: 5px 0;
  }

  .order-form-success {
    background: rgba(0, 128, 0, 0.025);
    border: 1px solid green;
    color: green;
    display: flex;
    flex-direction: column;
    font-weight: 600;
    margin: 5px 0;
    padding: 10px;
  }

  .order-form-section {
    background: #eee;
    padding: 10px;
    margin: 5px 0;
  }
}

.choose-supplies {
  li + li {
    margin-top: 10px;
  }

  label {
    display: flex;
    align-items: center;
  }

  .supply-checkbox-container {
    padding-right: 15px;
  }

  input {
    margin: 0;
  }

  .description {
    color: #999;
  }

  .choose-supplies-button-group {
    display: flex;
    justify-content: space-around;
    padding-top: 15px;
    background: #eee;
    margin: 1rem -20px -1rem -20px;
    margin-top: 1rem;

    button {
      width: 25%;
    }
  }
}

.order-summary {
  ul + ul {
    margin-top: 0.83rem;
  }

  li {
    border: 1px solid #d7d7d7;
    padding: 15px;
    font-size: 0.825em;
    display: flex;
    margin-bottom: 15px;
  }

  .order-summary-checkmark-container {
    height: 30px;
    width: 30px;
    display: flex;
  }

  .order-summary-item-description {
    margin-left: 15px;
  }

  .order-form-checkbox {
    font-size: 1rem;
    font-weight: 500;
    color: #2d2d2d;
  }
}

.order-form-input {
  &.invalid {
    color: red;
    font-weight: bold;
  }

  padding: 5px 0px;

  input {
    margin: 0;
  }

  &.order-form-checkbox {
    display: flex;
    align-items: center;

    div:first-child {
      padding-right: 10px;
      display: flex;
      align-items: center;
    }
  }

  &.order-form-select {
    select {
      margin: 0;
    }
  }

  &.order-form-textarea {
    textarea {
      margin: 0;
    }
  }

  &.order-form-radio {
    > div {
      display: flex;
    }

    label {
      display: flex;

      > input {
        margin-right: 5px;
        display: block;
      }
    }

    label + label {
      margin-left: 20px;
    }
  }

  &.order-form-address {
    .order-form-address-label {
      font-size: 0.875rem;
      color: #4d4d4d;
    }
  }
}

.orderFormContainer {
  margin-bottom: 15px;
  border: 1px solid rgb(221, 221, 221);
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 15px;
  position: relative;

  .orderFormX {
    position: absolute;
    right: 0;
    top: 0;
    font-size: 20px;
    padding: 5px;
    line-height: 1;
    cursor: pointer;
  }
}

.eligible {
  margin-left: 15px;
  background-color: green;
  border-radius: 10px;
  padding-left: 10px;
  padding-right: 10px;
  color: white;
}

.notEligible {
  margin-left: 15px;
  background-color: red;
  border-radius: 10px;
  padding-left: 10px;
  padding-right: 10px;
  color: white;
}

.eligbleDisclaimer {
  position: relative;
  top: 90%;
  color: #4d4d4d;
}

.eligbleDisclaimerParent {
  position: relative;
  top: 90%;
}

/* Phone Portrait and Landscape */
@media only screen and (max-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
  .order-form-input {
    display: inherit;
    margin: 0;
    padding: 0;
  }
}
